<template>
  <div class="contract-cont">
    <div class="code">
      <img @click="getData" :src="imgPath + info.img + timp" alt="二维码过期，请点击刷新">
    </div>

    <div class="box shadow">
      <div class="form-group line">
        <div class="label">卡号</div>
        <div class="cont">
          {{info.code}}
        </div>
      </div>
      <div class="form-group line">
        <div class="label">卡密</div>
        <div class="cont">
          {{info.pwd}}
        </div>
      </div>
    </div>

    <div class="a-cont">
      <div class="name">请将此页面展示给门店销售人员</div>
      <div class="title">使用期间请勿离开次页面</div>
    </div>

    <div class="btns">
      <div class="btn btn-1" @click="goBack">返回</div>
      <cube-button class="btn btn-2" @click="goPath('/coupon/store/list')">查看可用门店</cube-button>

    </div>

    <Position></Position>

  </div>
</template>

<script>
import Position from '@/components/Position'
export default {
  name: 'ScarchImei',
  components: {
    Position
  },
  data () {
    return {

      timp: '',
      imgPath: this.$init.apis.default + '/ticket/getImageIO?',
      id: this.$route.params.id,
      order: this.$route.query.order,
      info: {},

    }
  },
  mounted () {
    this.getData();
    window.ticketIn = setInterval(()=>{
      this.getTicketStatus()
    }, 3000)
  },
  methods: {
    /**
     * 服务转移
     */
    transition () {
      this.$createDialog({
        type: 'confirm',
        title: '是否进行服务转移',
        content: '',
        confirmBtn: {
          text: '确定',
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: '取消',
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          // 确定
          this.$router.replace('/coupon/transfer/' + this.order)
        },
        onCancel: () => {
          // 取消
        }
      }).show()
    },
    /**
     * 查询优惠券使用信息
     */
    getTicketStatus () {
      const vm = this;
      let postData = {
        id: this.id,
        order: this.order
      }
      this.$http
        .post("/ticket/ticketStatus", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.state  == 2 || response.data.body.state  == 4){
              clearInterval(window.ticketIn);
              vm.transition()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 更换图片
     */
    changeImg () {
      if(window.timer){
        clearTimeout(timer)
      }
      window.timer = setTimeout(() =>{
        this.timp = '&temp=' + (new Date()).getTime()
      }, 2*60*1000)
    },
    /**
     * 获取数据
     */
    getData () {
      const vm = this;
      if(! this.id){
        this.$createToast({
          txt: '无优惠券信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        id: this.id,
        order: this.order
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/ticket/ticketDetail", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            vm.info = response.data.body;
            vm.info.img = 'code='+vm.info.code+'&pwd=' + vm.info.pwd;
            vm.changeImg()
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 跳转页面
     */
    goPath (path) {
      this.$router.push(path)
    },
    /**
     * 返回页面
     */
    goBack () {
      this.$router.back(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.code{
  padding: 80px;
  img{
    box-sizing: border-box;
    text-align: center;
    font-size: 30px;
    color: #333333;
    line-height: 150px;
    display: block;
    height: 440px;
    width: 440px;
    margin: 0 auto;
    background-color: #eee;
    border: none;
  }
}
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 150px;
    }
    .cont{
      flex: 10;
      text-align: right;
      padding: 30px 0;
    }
  }
}

.a-cont{
  padding: 48px 0;
  margin-bottom: 80px;
  .name{
    font-size: 30px;
    color: #333333;
    text-align: center;
    line-height: 30px;
  }
  .title{
    font-size: 30px;
    color: #E74857;
    text-align: center;
    line-height: 30px;
    margin-top: 16px;
  }
}
.btns{
  display: flex;
  padding-bottom: 100px;
}
.btn{
  height: 70px;
  box-sizing: border-box;
  padding: 0;
  outline: none;
  list-style: none;
  font-size: 30px;
  text-align: center;
  line-height: 70px;
}
.btn-1{
  border: 1px solid #208170;
  border-radius: 50px;
  width: 209px;
  border-color: #208170;
  margin-right: 30px;
  margin-left: 60px;
  color: #208170;
}
.btn-2{
  width: 390px;
  background-color: #208170;
  border-radius: 50px;
}
.hide{
  display: none;
}
</style>